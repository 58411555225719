<template>
  <div class="station" v-loading="showLoad" element-loading-text="拼命加载中">
    <div class="data-box">
      <div class="left-data">
        <!-- 箱体信息 -->
        <div class="data-num">
          <div class="items">
            <div class="box">
              <img src="../assets/img/station_icon.png" />
              <div class="info">
                <span class="title">箱体{{ boxItem1.box }}</span>
                <span class="value" v-if="Number(boxItem1.cubage) === 0">空</span>
                <span class="value" v-if="Number(boxItem1.cubage) === 1">半满</span>
                <span class="value" v-if="Number(boxItem1.cubage) === 2">满</span>
              </div>
            </div>
            <div class="box">
              <img src="../assets/img/station_icon.png" />
              <div class="info">
                <span class="title">箱体{{ boxItem2.box }}</span>
                <span v-if="detailSimpleVos.length === 1" class="value"
                  >无</span
                >
                <template v-else>
                  <span class="value" v-if="Number(boxItem2.cubage) === 0">空</span>
                  <span class="value" v-if="Number(boxItem2.cubage) === 1">半满</span>
                  <span class="value" v-if="Number(boxItem2.cubage) === 2">满</span>
                </template>
              </div>
            </div>
          </div>
          <div class="items">
            <div class="box">
              <img src="../assets/img/station_icon.png" />
              <div class="info">
                <span class="title">清运总次数</span>
                <div>
                  <span class="value">{{ stData.cleanCount }}</span>
                  <span class="value2">(次)</span>
                </div>
              </div>
            </div>
            <div class="box">
              <img src="../assets/img/station_icon.png" />
              <div class="info">
                <span class="title">报警总次数</span>
                <div>
                  <span class="value">{{ stData.alarmCount }}</span>
                  <span class="value2">(次)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 转运站信息 -->
        <div class="data-info data-item">
          <el-row class="topRow">
            <el-col :span="12"
              ><div class="grid-content table-title">
                {{ stData.transferPointName }}
              </div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content name" style="color: #666666">
                更新时间：
              </div></el-col
            >
            <el-col :span="8"
              ><div class="grid-content nameVal">
                {{ stData.updateTime }}
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content name">清运车辆：</div></el-col>
            <el-col :span="8"
              ><div class="grid-content nameVal" :title="stData.serveName">
                {{ stData.vehicle }}
              </div></el-col
            >
            <el-col :span="4"><div class="grid-content name">联系电话：</div></el-col>
            <el-col :span="8"
              ><div class="grid-content nameVal" :title="stData.serveName">
                {{ stData.transferPhone }}
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content name">当前状态：</div></el-col>
            <el-col :span="8"
              ><div class="grid-content nameVal">
                <span class="state-close" v-if="stData.status === 0">未开启</span>
                <span class="state-open" v-if="stData.status === 1">正常运行</span>
                <span class="state-warn" v-if="stData.status === 2">报警</span>
              </div></el-col
            >
            <el-col :span="4"><div class="grid-content name">清运单位：</div></el-col>
            <el-col :span="8"
              ><div class="grid-content nameVal" :title="stData.serveName">
                {{ stData.managementDeptName }}
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content name">转运站地址：</div></el-col>
            <el-col :span="19"
              ><div class="grid-content nameVal" :title="stData.serveName">
                {{ stData.location }}
              </div></el-col
            >
          </el-row>
        </div>
      </div>
      <div class="video-box" ref="stationvideo">
        <div class="videItem">
          <!-- <el-carousel :interval="999999" arrow="never" height="570px">
          <el-carousel-item class="videItem"> -->
            <div class="stationvideo">
              
              <div id="video-container0" class="stationvideo-item" ></div>
            </div>
            <div class="stationvideo">
              
              <div id="video-container1" class="stationvideo-item"></div>
             
              <div id="video-container2" class="stationvideo-item" style="margin-top:10px"></div>
            </div>
         
        </div>
      </div>
    </div>
    <div class="chart-box data-item">
      <div class="chart-header">
        <span class="table-title" id="show">承载状态</span>
        <div class="chart-info">
          <div class="box1">
            <span>箱体1实时数据：</span>
            <span class="state-open blod" v-if="boxItem1.cubage === 0">空</span>
            <span class="state-open blod" v-if="boxItem1.cubage === 1">半满</span>
            <span class="state-warn blod" v-if="boxItem1.cubage === 2">满</span>
          </div>
          <div class="box2">
            <span>箱体2实时数据：</span>
            <span v-if="detailSimpleVos.length === 1" class="blod"
              >无</span
            >
            <template v-else>
              <span class="state-open blod" v-if="boxItem2.cubage === 0">空</span>
              <span class="state-open blod" v-if="boxItem2.cubage === 1">半满</span>
              <span class="state-warn blod" v-if="boxItem2.cubage === 2">满</span>
            </template>
          </div>
        </div>
        <div>
          <span>日期选择：</span>
          <el-date-picker
            placeholder="请选择日期"
            type="date"
            @change="changeDay"
            value-format="yyyy-MM-dd"
            v-model="selectDate"
            size="mini"
            :editable="false"
          ></el-date-picker>
        </div>
      </div>
      <div class="chart-item">
        <Echart :option="echartData"></Echart>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "./Echart";
import axios from "../request";
import EZUIKit from "ezuikit-js";

export default {
  name: "Station",
  props: ["name"],
  components: { Echart },
  watch: {},
  data() {
    return {
      id: "",
      curDay: "",
      selectDate: "",
      showLoad: true,
      stData: {
        // id: 6, //转运站id
        // toiletId: 1, //公厕id
        // toiletName: "香山路公厕", //公厕名称
        // deviceAddress: "",
        // box: "",
        // transferPointName: "香山路转运站1", //转运站名称
        // managementDeptId: 1, //管养单位id
        // managementDeptName: "油城环卫", //管养单位
        // longitude: "121321131", //经度
        // latitude: "5213211", //纬度
        // location: "香三路与香山路交叉路口1", //地址
        // vehicle: "鲁E7487481", //清运车辆
        // transferPhone: "7487487487481", //联系电话
        // alarmCount: 0,
        // cleanCount: 0,
        // status: 1, //状态 0未开启 1 正常 2 报警
        // createTime: "2021-03-15 11:59:37", //添加时间
        // updateTime: "2021-03-15 14:03:21", //修改时间
      },
      boxItem1: {
        id: 6, //详情箱体id
        deviceAddress: "003", //箱体地址
        transferPointId: 6, //转运站id
        box: "1", //箱体号
        cubage: "0", //空
      },
      boxItem2: {
        id: 1,
        deviceAddress: "1",
        transferPointId: 1,
        box: "2",
        cubage: "",
      },
      videoList: [],
      echartData: null,
      detailSimpleVos:[],
      player: [1, 2, 3],
      playerList:[]
    };
  },
  methods: {
    chartData() {
      return {
        tooltip: {
          trigger: "axis",
          formatter: function(v) {
            let text  = ''
            let texts = ''
            if (v[0].value ===0) {
              text = '空'
            } else if (v[0].value ===1) {
              text = '半满'
            } else if (v[0].value ===2) {
              text = '满'
            }
            if (v[1].value ===0) {
              texts = '空'
            } else if (v[1].value ===1) {
              texts = '半满'
            } else if (v[2].value ===2) {
              texts = '满'
            }
            
            let res = '箱体1的状态为:' + text +'<br/>箱体2的状态为:'+ texts
            return res;
            
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
          top: 30,
        },
        xAxis: {
          type: "category",
          name: "h",
          boundaryGap: false,
          data: [],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          // axisLabel: {
          //   show: true, // 默认为true
          //   // interval: 0, // 设置x轴文本标签全部显示
          //   // rotate: 45, //标签旋转角度，对于长文本标签设置旋转可避免文本重叠
          //   formatter: function(data) {
              
          //   }
          // }
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 2,
          // splitNumber: 2,
          interval: 1,
          axisLabel: {
            formatter(v, i) {
              
              if (v === 0) return "空";
              if (v === 1) return "半满";
              if (v === 2) return "满";
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "箱体1",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [],
            itemStyle: { 
              normal: { 
                label: { 
                  show: false
                } 
              } 
            },
          },
          {
            name: "箱体2",
            type: "line",
            lineStyle: {
              color: "#FF791F",
              width: 3,
            },
            color: "#FF791F",
            data: [],
            markLine: {
              label: {
                position: "middle",
                formatter: "满",
                fontSize: "16",
              },
              animation: false,
              data: [{ yAxis: 2222 }],
              symbol: "none",
              silent: true,
              lineStyle: {
                type: "dashed",
                width: 2,
                color: "#FFB712",
              },
            },
          },
        ],
      };
    },
    changeDay(e) {
      console.log(e)
      this.getChartData(this.id, e);
    },
    async getStationData(id) {
      this.id = id;
      this.showLoad = false;
      const video0 = document.querySelector('#video-container0')
      const video1 = document.querySelector('#video-container1')
      const video2 = document.querySelector('#video-container2')
      if (video0 && video1 &&video2) {
        video0.innerHTML = ''
        video1.innerHTML = ''
        video2.innerHTML = ''
      }
      let data = await axios.get(`/transferPoint/findById/${id}`);

      if (data) {
        console.log(data)
        this.stData = data;
        this.detailSimpleVos = data.detailSimpleVos
        console.log(this.stData);
        this.boxItem1 = data.detailSimpleVos[0];
        if (data.detailSimpleVos[1]) {
          this.boxItem2 = data.detailSimpleVos[1];
        }
        console.log(this.boxItem2);
      }

      // 处理视频
      if (this.videoList.length > 0) {
        // this.$router.go(0)
        //  this.videoList.forEach((item) => item.stop());
        this.videoList = [];
        console.log(this.videoList);
        console.log(this.$refs.stationvideo);
        console.log(this.videoList[0]);
        // this.$refs.stationvideo.innerHTML = 
        // `<div class="videItem">
        //     <div class="stationvideo">
        //           <iframe
        //             :src="videoList[0]"
        //             id="svideo1"
        //             class="stationvideo-item"
        //             allowfullscreen
        //           ></iframe>

        //         </div>
        //         <div class="stationvideo">
        //           <iframe
        //             :src="videoList[1]"
        //             id="svideo2"
        //             class="stationvideo-item"
        //             allowfullscreen
        //           ></iframe>
        //           <iframe
        //             :src="videoList[2]"
        //             id="svideo3"
        //             class="stationvideo-item"
        //             allowfullscreen
        //           ></iframe>
        //         </div>
        //     </div>
        //     `;
        //  console.log(this.$refs.stationvideo);
      }

      let video = await axios.get(`/video//findByTransferPointId/${id}`);
      if (video) {
        // video.forEach((item, index) => {
        //     this.videoList[index] = new EZUIKit.EZUIKitPlayer({
        //         id: "video-container" + index,
        //         accessToken: item.accessToken,
        //         url: item.videoAddress,
        //         templete: 'standard',
        //         header: ['capturePicture', 'save'],
        //         footer: ['hd', 'fullScreen'],
        //     })
        // })
        this.playerList = video.map(item =>  {
          return {
            accessToken: item.accessToken,
            url: item.videoAddress
          }
        })
        console.log(this.playerList)
        if (this.playerList.length != 0){
          for (const i in this.player) {
            for (const j in this.playerList) {
              if (i == j) {
                this.player[i] = new this.$ezuikit.EZUIKitPlayer({
                  id: `video-container${i}`, // 视频容器ID
                  templete: 'standard',
                  // header: ['capturePicture', 'save'],
                  footer: ['hd', 'fullScreen'],
                  accessToken: this.playerList[j].accessToken,
                  url: this.playerList[j].url
                })
              }
            }
          }
        } else {
          video0.innerHTML = ''
          video1.innerHTML = ''
          video2.innerHTML = ''
        }
      }

      // 图表数据
      this.getChartData(id);
      // this.showLoad = false;
    },

    async getChartData(id, time = "") {
      const query = { transferPointId: id, dateTime: time === null ? "" : time };
      let data = await axios.get("/transferPointRealDay/dayReport", {
        params: query,
      });
      console.log(data)
      if (data) {
        let chart = this.chartData();
        console.log(chart);
        let xLabel = (chart.xAxis.data = data.oneDayReportVoList.map((item) => item.hour));
        chart.series[0].data = data.oneDayReportVoList.map((item) => item.cubage);
        chart.series[1].data = data.twoDayReportVoList.map((item) => item.cubage);
        if (time) {
          let selTime = new Date(...time.split("-")).getTime();
          let curTime = new Date(...this.curDay.split("-")).getTime();
          if (selTime > curTime) {
            chart.series[0].data = [];
            chart.series[1].data = [];
          }
          if (selTime === curTime) {
            let hour = new Date().getHours();
            let index = xLabel.indexOf(hour) + 1;
            chart.series[0].data.splice(index);
            chart.series[1].data.splice(index);
          }
        } else {
          let hour = new Date().getHours();
          let index = xLabel.indexOf(hour) + 1;
          chart.series[0].data.splice(index);
          chart.series[1].data.splice(index);
        }
        console.log(chart)
        this.echartData = chart;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to.params.name)
    this.getStationData(to.params.name);
    this.getChartData(to.params.name);
    next();
  },
  mounted() {
    const time = new Date();
    this.curDay = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    console.log(this.name)
    this.getStationData(this.name);
    this.getChartData(this.id);
  },
};
</script>

<style scoped lang="scss">
.data-item {
  border-top: solid 3px #2f78ff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: white;
}
.station {
  flex: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.box1 {
  color: #2275ff;
}
.box2 {
  color: #2275ff;
  margin-left: 50px;
}
.data-box {
  flex: auto;
  box-sizing: border-box;
  display: flex;
  padding-bottom: 15px;
}
.chart-box {
  height: 30%;
  display: flex;
  flex-direction: column;
  flex: none;
}
.chart-item {
  flex: auto;
}
.chart-info {
  display: flex;
}
.left-data {
  // flex: auto;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  box-sizing: border-box;
  width: 40%;

  .data-num {
    flex: auto;
    display: flex;
    flex-direction: column;
    .items {
      flex: auto;
      display: flex;
      &:last-child {
        margin-top: 10px;
      }
      .box {
        background-color: white;
        flex: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-left: 10px;
        }
        .info {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          justify-content: space-around;
          align-items: center;
          width: 50%;
          .title {
            color: #778ca2;
          }
          .value {
            color: $blue;
            font-size: 1.6rem;
            font-weight: bold;
          }
          .value2 {
            color: $blue;
          }
        }
      }
    }
  }
  .data-info {
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
  }
}
.video-box {
  flex: auto;
}

.blod {
  font-weight: bold;
  color: #333333;
}
.state-close {
  color: gray;
}
.state-open {
  color: #00da24;
}
.state-warn {
  color: #fb4c4c;
}
.el-row {
  margin: 10px 0 25px 0;
}
.table-title {
  color: $blue !important;
  font-size: 1.2rem;
  font-weight: bold;
}
.topRow {
  padding: 10px 0px 15px 0px;
  border-bottom: solid 1px #e9ecf7;
}
.name {
  text-align: left;
  color: #666666;
}
.nameVal {
  font-weight: bold;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
</style>

<style lang="scss">
.videItem {
  width: 100%;
  height: 580px;
  display: flex;

  // flex-direction: column;
  // justify-content: space-between;
}
.stationvideo {
  width: 100%;
  display: flex;
  flex-direction: column;
  // width: calc(100% - 5px);
  // justify-content: space-between;

  .stationvideo-item {
    flex: 1;
    width: calc(100% - 5px);
    background: url("../assets/img/no_video.png") center/50% 60% no-repeat, white;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}
</style>
